import React from 'react'
import Layout from "../../../components/layout";
import { Helmet } from 'react-helmet';

function Webdevelopment() {

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "Webdevelopment - Bart Sallé, freelance webdeveloper";
  const description = "Op zoek naar een freelance webdeveloper die gewoon jouw taal spreekt? Ik vertaal jouw wens naar een goede technische oplossing.";
  const url = "https://www.bartsalle.nl/diensten/webdevelopment/";

  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="overzichtdiensten" />
      </Helmet>
        <div className="container">
          <h1>Webdevelopment</h1>
          <p>Op zoek naar een freelance webdeveloper die gewoon jouw taal spreekt?</p>
          <p>Met ruim 14 jaar ervaring heb ik inmiddels aan zoveel projecten gewerkt dat ik snel jouw wens kan vertalen naar een goede technische oplossing. Zonder daarbij te technisch te worden in mijn verhaal naar jou toe. Ik houd het helder en denk graag mee zodat we een zo goed mogelijk eindproduct krijgen.</p>
          <p>Ik help je dus graag met het realiseren van jouw wens!</p>
          <h2>Wordpress developer</h2>
          <p>Tegenwoordig werk ik met name met Wordpress en ben ik gespecialiseerd in het schrijven van Wordpress plugins, Wordpress koppelingen en Wordpress templates. Je kunt dus bij me terecht voor al het Wordpress maatwerk. Al heb ik in het verleden ook veel voor andere CMS systemen ontwikkeld en met PHP frameworks als Laravel en CakePHP gewerkt.</p>
          <h2>Wordpress / WooCommerce koppeling</h2>
          <p>Wil je jouw Wordpress website of WooCommerce webshop koppelen aan een extern systeem? Ik heb hier jarenlange ervaring mee! Je kunt denken aan het importeren van productfeeds in een WooCommerce webshop maar ook aan het schrijven van API koppelingen zodat er gemakkelijk data van en naar een Wordpress website of WooCommerce webshop gestuurd kan worden.</p>
          <h2>Maatwerk Wordpress website (template)</h2>
          <p>Wil je een maatwerk Wordpress template, op basis van een aangeleverd ontwerp (Adobe XD, Figma, Photoshop etc.) ? Ik bouw het probleemloos voor je om! Uiteraard volledig responsive en in de juiste semantische code.</p>
          <h2>Styling</h2>
          <p>Styling valt ook enigszins onder het kopje maatwerk. Als frontend webdeveloper werk ik dagelijks met CSS en styling is toch wel één van mijn favoriete bezigheden. Ik help je daarom graag bij het (re)stylen van je website of webapplicatie.</p>
          <h2>React</h2>
          <p>Ik houd mij ook bezig met het schrijven van front-end omgevingen op basis van React. React is een Javascript bibliotheek waar diverse grote websites als Facebook, Netflix, Instagram en Airbnb gebruik van maken. De content kan gewoon in uw favoriete CMS systeem (zoals Wordpress) blijven en als headless CMS gebruikt worden. Maar de frontend is ineens vele malen sneller dan dat je gewend was. Deze website is er een voorbeeld van.</p>
        </div>
    </Layout>
  )
}

export default Webdevelopment